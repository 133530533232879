import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home", // Global
      component: Home,
    },

    {
      path: "/showArea/:area",
      name: "Switch to first level section", // Global

      component: () => import("../views/ShowArea.vue"),
    },

    {
      path: "/createInArea/:area",
      name: "Create new object",

      component: () => import("../views/CreateInArea.vue"), // Global
    },
    {
      path: "/stationsetupsoverview",
      name: "Show a map based overwiev",

      component: () => import("../views/StationSetupsOverview.vue"), // Global
    },

    {
      path: "/editdataset/:kind/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Edit a single capture event instance", // Global, first level (s. navigation) Datasets

      component: () => import("@/views/EditDataset.vue"),
    },

    {
      path: "/captureevent/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Show a single capture event instance",

      component: () =>
        import("@/views/Landingpages/CaptureeventLandingpage.vue"), // Schema
    },

    {
      path: "/projects/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Show a single project instance",

      component: () => import("@/views/Landingpages/ProjectLandingpage.vue"), // Schema
    },
    {
      path: "/stationlayouts/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Show a single station layouts instance",

      component: () =>
        import("@/views/Landingpages/StationLayoutsLandingpage.vue"), // Schema
    },
    {
      path: "/stationsetup/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Show a single station setup instance",

      component: () =>
        import("@/views/Landingpages/StationSetupLandingpage.vue"), // Schema
    },
    {
      path: "/sensorsetup/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Show a single sensor setup instance",

      component: () =>
        import("@/views/Landingpages/SensorSetupLandingpage.vue"), // Schema
    },
    {
      path: "/sensor/:id([a-zA-Z0-9.]+/[a-zA-Z0-9]+)",
      name: "Show a single sensor instance",

      component: () => import("@/views/Landingpages/SensorLandingpage.vue"), // Schema
    },

    {
      path: "/about",
      name: "About",

      component: () => import("../views/About.vue"),
    },
    {
      path: "/imprint",
      name: "Imprint",

      component: () => import("../views/Imprint.vue"),
    },
  ],
});
