import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_loading = _resolveComponent("o-loading")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_o_loading, {
      "full-page": true,
      active: _ctx.isLoading,
      "can-cancel": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_o_icon, {
          pack: "fa",
          icon: "spinner",
          size: "large",
          spin: ""
        })
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}