<template>
  <div>
    <nav
      class="breadcrumb"
      aria-label="breadcrumbs"
      v-if="structure.length > 0"
    >
      <ul>
        <li>
          <router-link to="/showArea/projects">Project Overview</router-link>
        </li>
        <li v-for="(item, index) in structure" :key="item">
          <router-link
            :to="linkPrefix[item.type] + item.id"
            :class="['is-active' ? index == structure.length - 1 : '']"
            >{{ labels[item.type] }}</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import CordraMixin from "@/mixins/cordra";

export default {
  name: "TheBreadcrumbs",
  // eslint-disable-next-line no-undef
  props: ["objectID"],
  mixins: [CordraMixin],

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      labels: {
        [process.env.VUE_APP_CORDRA_DATATYPE_PROJECT]: "Project",
        [process.env.VUE_APP_CORDRA_DATATYPE_STATIONSLAYOUT]: "Station Layout",
        [process.env.VUE_APP_CORDRA_DATATYPE_STATIONSSETUP]: "Station Setup",
        [process.env.VUE_APP_CORDRA_DATATYPE_SENSORSETUP]: "Sensor Setup",
        [process.env.VUE_APP_CORDRA_DATATYPE_SENSOR]: "Sensor",
      },

      linkPrefix: {
        [process.env.VUE_APP_CORDRA_DATATYPE_PROJECT]: "/projects/",
        [process.env.VUE_APP_CORDRA_DATATYPE_STATIONSLAYOUT]:
          "/stationlayouts/",
        [process.env.VUE_APP_CORDRA_DATATYPE_STATIONSSETUP]: "/stationsetup/",
        [process.env.VUE_APP_CORDRA_DATATYPE_SENSORSETUP]: "/sensorsetup/",
        [process.env.VUE_APP_CORDRA_DATATYPE_SENSOR]: "/sensor/",
      },

      structure: [],
    };
  },

  methods: {},

  computed: {},

  async created() {
    this.cordraCreateTemporaryClient()
      .callMethod(this.objectID, "getHierarchyInProject")
      .then((result) => {
        this.structure = result.reverse();
        console.log("Result reversed Breadcrumbs:", result);
      })
      .catch((error) => {
        console.log("Error in TheBreadcrumbs", error);
      });
  },
};
</script>
