
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "TheLoader",

  computed: {
    isLoading() {
      return this.$store.getters["loader/getStatus"];
    },
  },
});
